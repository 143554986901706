import React from 'react'
import s from './classCard.module.scss'
import classNames from 'classnames'
import { Button } from '../button/button';

export const NotNextClassCard = ({className, role = 'student'}) => {
	return (
		<div className={classNames(className, s.nextClassCard)}>
			<div className={s.title}>
				Следующие занятие
			</div>
			{role === 'student' ? <div className={s.notFound}>
					Ой, похоже <br/>
					ты еще не записался
			</div> : <div className={s.notFound}>
					У вас пока что <br/>
					нет новых занятий
			</div>}
			<img src={process.env.REACT_APP_STORAGE_URL + "images/clock-dynamic-clay.png"} alt="" className={s.notFoundImg}/>
			{role === 'student' ? <Button label={'Записаться'} className={s.submit} size="small" to={'/teachers'}/> : ''}
		</div>
	)
}
