import { useState } from "react";
import { VideoConf } from "../video-conf/video-conf";
import { Tooltip } from "react-tooltip";
import { useNavigate, useParams } from "react-router-dom";
import { useAppointment } from "../../../hooks/useAppointment";
import { useMedia } from "use-media";
import { useAppointmentToken } from "../../../hooks/useAppointmentToken";
import { LiveKitRoom } from "@livekit/components-react";
import s from "./appointment-wrapper.module.scss";
import { IconChevronLeft } from "@tabler/icons-react";
import classNames from "classnames";
import { TimeDisplay } from "../time-display/time-display";
import { Loader } from '../loader/loader';
import { useAppointmentRecord } from '../../../hooks/useAppointmentRecord';
import { AppointmentHeader } from "../appointment-header/appointment-header";
import './livekit.scss'
import RouterBlocker from "react-route-blocker";
import { BlockerRouterModal } from "../../profile/modals/blocker-router-modal/blocker-router-modal";

export const AppointmentWrapper = ({
  AppointmentNote,
  AddNoteCard,
  HomeworkCard,
  AddHomeworkCard,
  isTeacher,
}) => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
	
  const [mode, setMode] = useState(0);
  const [isConf, setIsConf] = useState(false);
  const [isBottomSheetShown, setIsBottomSheetShown] = useState(false);

  const { data: appointment, isFetching: appointmentIsFetching, refetch } =
    useAppointment(appointmentId);
  const isMobile = useMedia({ maxWidth: "768px" });
	

  const date = new Date(appointment?.starts_at);
  const dateEnd = new Date(appointment?.ends_at);
  const today = new Date();

  const timeToRequest = new Date(date.getTime() - 15 * 60 * 1000);
  const shouldRequest = today >= timeToRequest;
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
  const confIsStart = today.getTime() >= date.getTime() && today.getTime() < dateEnd?.getTime();
  const confIsEnd = dateEnd?.getTime() < today?.getTime();

  const { data: appointment_token, status } = useAppointmentToken({
    id: appointmentId,
    shouldRequest: shouldRequest && !confIsEnd,
  });
	const { data: appointmentRecord, status: downloadStatus } = useAppointmentRecord(confIsEnd ? appointmentId : null)
	
  const disabled = status === "pending" || status === "error";
  const downloadDisabled = downloadStatus === "pending" || downloadStatus === "error";

  return (
    <LiveKitRoom
      video={true}
      audio={true}
      token={appointment_token}
      onDisconnected={() => setIsConf(false)}
      serverUrl={process.env.REACT_APP_LIVE_KIT_SERVER_URL}
      data-lk-theme="default"
      className={s.wrapper}
      connect={isConf}
    >
			<RouterBlocker 
				when={ isConf }
				bodyRender={(props) => {
					return (
						<BlockerRouterModal isOpen={props.isActive ? 'blocker-router-modal' : ''} setOpen={(s) => {
							if(!s){
								props?.onCancel()
							}
						}} modalParams={{blockerProps: props}}/>
					)
				}}
			/>
      <button className={s.backToList} onClick={() => navigate(-1)}>
        <IconChevronLeft /> Назад к списку занятий
      </button>

      <div className={s.flexWrapper}>
        {isMobile && isBottomSheetShown && (
          <div
            onClick={() => setIsBottomSheetShown(false)}
            className={s.overlayBottomSheet}
          ></div>
        )}
        <div
          className={classNames(
            s.appointmentColumn,
            isMobile && isBottomSheetShown && s.shown
          )}
        >
          <AppointmentHeader {...{mode, setMode, appointmentIsFetching, setIsBottomSheetShown, isMobile, refetch, isTeacher}} shown={isBottomSheetShown}  has_unreviewed_homework={appointment?.has_unreviewed_homework} has_new_homework={appointment?.has_new_homework}/>
          <div className={s.scrollerContent}>
            {
              [
                <>
                  {appointment?.notes?.map((note) => (
                    <AppointmentNote {...{ note, appointmentId }} />
                  ))}
                  {!appointmentIsFetching ? "" : <Loader />}
                  {AddNoteCard ? <AddNoteCard {...{ appointmentId }} /> : ""}
                </>,
                <>
                  {appointment?.homework?.map((homework) => (
                    <HomeworkCard
                      {...{ homework, appointmentId, appointment }}
                    />
                  ))}
                  {!appointmentIsFetching ? "" : <Loader />}
                  {AddHomeworkCard ? (
                    <AddHomeworkCard {...{ appointmentId }} />
                  ) : (
                    ""
                  )}
                </>,
              ][mode]
            }
          </div>
        </div>
        <div className={s.conferenceCard}>
          <div className={s.conferenceHeader}>
            <div className={s.title}>
              {isMobile && (
                <button className={s.backToList} onClick={() => navigate(-1)}>
                  <IconChevronLeft />
                </button>
              )}
              Конференция
            </div>
            <div className={s.timer}>
              {confIsEnd ? (
                "Завершено"
              ) : isToday ? (
                confIsStart ? (
                  <TimeDisplay startsAt={date} />
                ) : (
                  <>
                    Начнется в{" "}
                    {date.toLocaleString("ru-RU", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </>
                )
              ) : (
                date.toLocaleDateString("ru-RU", { formatMatcher: "basic" })
              )}
            </div>
          </div>
          <VideoConf
            {...{ isConf, setIsConf, shouldRequest, downloadDisabled, appointmentRecord, disabled, confIsEnd }}
          />
        </div>
      </div>
      <Tooltip
        id="tooltip-has-new-homework-result"
        content={
          isTeacher
            ? "Есть непроверенное домашнее задание"
            : "Есть невыполненное домашнее задание"
        }
      />
    </LiveKitRoom>
  );
};
