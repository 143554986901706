import s from './add-card-fail.module.scss'
import { Button } from 'shared/button/button';
import { IconCreditCardOff } from '@tabler/icons-react';

export const AddCardFail = () => {
	return (
		<div className={s.wrapper}>
			<div className={s.card}>
				<div className={s.content}>
					<IconCreditCardOff size={100} className={s.icon}/>
					<div className={s.title}>Карту не удалось привязать!</div>
					<div className={s.subtitle}>Попробуйте привязать карту заново или обратитесь в поддержку.</div>
				</div>
				<div className={s.buttons}>
					<Button theme='secondary' label='Вернуться к настройкам' to={'/settings'} className={s.btn}/>
				</div>
			</div>
		</div>
	)
}
