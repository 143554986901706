import React from 'react'
import s from './student-analytics.module.scss'
import { Button } from '../button/button';
import classNames from 'classnames';
import { useAnalytics } from '../../../hooks/useAnalytics';

export const StudentAnalytics = ({className}) => {
	const lineWidth = (number, max) => {
		return (number / max)*100
	}

	const {data: analytics} = useAnalytics()

	return (
		<div className={classNames(className, s.card)}>
			<div className={s.title}>
				Личный рейтинг
			</div>
			<ul className={s.ratingLines}>
				<li>
				<div className={s.ratingTitle}>Пройденых занятий:</div>

					<div className={s.ratingWrapper}>
						<div className={s.ratingLineNumber}>{analytics?.appointments_count}/100</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(analytics?.appointments_count, 100) + '%'}}></span>
						</div>
					</div>
				</li>
				<li>
					<div className={s.ratingTitle}>Часов за учебой:</div>
					<div className={s.ratingWrapper}>
						<div className={s.ratingLineNumber}>{Math.round(analytics?.appointment_hours * 10) / 10}/100</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(analytics?.appointment_hours, 100) + '%'}}></span>
						</div>
					</div>
				</li>
			</ul>
			<Button label={'Заниматься еще'} className={s.btn} to="/teachers" size="small"/>
			<img src={"./images/fire-dynamic-color.png"} alt="" className={s.img}/>
		</div>
	)
}
