import "@livekit/components-styles";
import { ParticipantTile, RoomAudioRenderer, useTracks } from "@livekit/components-react";
import { Track } from "livekit-client";
import s from "./video-conf.module.scss";
import { ControlBar } from "./control-bar";
import { Layout } from "./layout";
import { Button } from "../button/button";
import classNames from "classnames";
import { ModalsContext } from "../../layouts/dashboard-layout/dashboard";
import { useContext, useEffect, useState } from "react";

function MyVideoConference() {
  const tracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: false },
  ]);

  return (
    <Layout tracks={tracks}>
      <ParticipantTile />
    </Layout>
  );
}

async function checkFileExists(url) {
  try {
    const response = await fetch(url, { method: 'HEAD' });

    return response.ok;
  } catch (error) {
    console.error('Error checking file existence:', error);
    return false;
  }
}

export const VideoConf = ({ isConf, setIsConf, appointmentRecord, shouldRequest, disabled, confIsEnd }) => {
	const { setModal } = useContext(ModalsContext)
	const [hasRecord, setHasRecord] = useState()

	useEffect(() => {
		const checkAppointmentVideo = async () => {
			if (appointmentRecord && appointmentRecord.url) {
				const exists = await checkFileExists(appointmentRecord.url);

				if (exists) {
					setHasRecord(true)
				} else {
					setHasRecord(false)
				}
			}
		};

		checkAppointmentVideo()
	}, [appointmentRecord])
	
	return (
    <>
      {isConf ? (
        <div className={s.wrapper}>
					<MyVideoConference />
          <RoomAudioRenderer />
          <ControlBar />
        </div>
      ) : (
        <>
          <div className={classNames(s.emptyText, confIsEnd && appointmentRecord ? s.withVideo : '')}>
						{confIsEnd ? <>
							{appointmentRecord && typeof hasRecord === 'boolean' ? 
							hasRecord === false ? 'Запись урока нет' :
							<video 
								className={s.video} 
								controls={true}>
								<source src={appointmentRecord.url} type="video/mp4" />
							</video> : 'Запись урока еще не загрузилась'}
						</> : 
            	shouldRequest
              ? "Вы уже можете подключиться к конференции"
              : <div className={s.emptyWithButton}>
								Конференция пока что не началась
								<Button label='Проверить подключение' className={s.fullBtn} theme="secondary" onClick={() => setModal('check-conf-modal')}/>
							</div>
						}
          </div>
          <div className={s.buttonWrapper}>
           {confIsEnd ?
							null
					  : <Button
              label="Подключиться"
              disabled={disabled}
              size="small"
              onClick={() => setIsConf(true)}
            />}
          </div>
        </>
      )}
    </>
  );
};
