import s from './information.module.scss'
import { useProfile } from 'hooks/useProfile';

export const Information = () => {
	const {data: profile} = useProfile()
	
	return (
		<div className={s.wrapper}>
			<div className={s.title}>Информация</div>
			<div className={s.card}>
				<div className={s.infoBlock}>
					<div className={s.infoTitle}>
						ДОКУМЕНТЫ
					</div>
					<div className={s.documents}>
						<a href="https://sozvuchno.ru/policy">Политика конфиденциальности</a>
						{profile?.role === 'teacher' && <a href={process.env.REACT_APP_STORAGE_URL + 'contract.pdf'} target='_blank' download={true}>Договор с преподавателем</a>}
					</div>
				</div>
				<div className={s.infoFlex}>
					<div className={s.infoBlock}>
						<div className={s.infoTitle}>
							СВЯЖИСЬ С НАМИ
						</div>
						<div className={s.info}>
							<div className={s.desc}>
							Мы рады новым сотрудничествам и готовы услышать идеи по улучшению нашего продукта.
							</div>
							<div className={s.links}><a href="mailto:contact@sozvuchno.ru">contact@sozvuchno.ru</a></div>
						</div>
					</div>
					<div className={s.infoBlock}>
						<div className={s.infoTitle}>
							СЛЕДИ ЗА НАМИ
						</div>
						<div className={s.info}>
							<div className={s.desc}>
							Следи за обновлениями и последними новостями в наших социальных сетях.
							</div>
							<div className={s.links}>
								<a href="https://t.me/sozvuchno_ed">Telegram</a>
								{/* <a href="">YouTube</a> */}
								<a href="https://vk.com/sozvuchno_music">Vk</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}