import React, { useEffect, useState } from 'react'
import { TeacherModal } from './teacher-modal/teacher-modal'
import { UpdateAvatarModal } from './update-avatar-modal/update-avatar-modal';
import { AddReviewModal } from './add-review-modal/add-review-modal';
import { RemoveAppointmentModal } from './remove-appointment/remove-appointment';
import { ViewReviewModal } from './view-review-modal/view-review-modal';
import { AppointmentModal } from './appointment-modal/appointment-modal';
import { EditScheduleModal } from './edit-schedule-modal/edit-schedule-modal';
import { EditTeacherProfileModal } from './edit-teacher-profile-modal/edit-teacher-profile-modal';
import { RemoveAppointmentsNoteModal } from './remove-appointments-note/remove-appointments-note';
import { RemoveHomeworkModal } from './remove-homework/remove-homework';
import { ConnectStudentModal } from './connect-student-modal/connect-student-modal';
import { StudentModal } from './student-modal/student-modal';
import { CheckConfModal } from './check-conf-modal/check-conf-modal';
import { BlockerRouterModal } from './blocker-router-modal/blocker-router-modal';

export const Modals = ({isOpen, setOpen, setModalParams, modalParams}) => {
	useEffect(() => {
		if(!isOpen){
			setModalParams(null)
		}
	}, [isOpen])

	return (
		<div>
			{{
				"teacher-modal": <TeacherModal {...{isOpen, modalParams, setOpen}}/>,
				"update-avatar-modal" : <UpdateAvatarModal {...{isOpen, modalParams, setOpen}}/>,
				"add-review-modal" : <AddReviewModal {...{isOpen, modalParams, setOpen}}/>,
				"view-review-modal": <ViewReviewModal {...{isOpen, modalParams, setOpen}}/>,
				"appointment-modal": <AppointmentModal {...{isOpen, modalParams, setOpen}}/>,
				"student-modal": <StudentModal {...{isOpen, modalParams, setOpen}}/>,
				"remove-appointment": <RemoveAppointmentModal {...{isOpen, modalParams, setOpen}}/>,
				"remove-appointments-note": <RemoveAppointmentsNoteModal {...{isOpen, modalParams, setOpen}}/>,
				"remove-homework": <RemoveHomeworkModal {...{isOpen, modalParams, setOpen}}/>,
				"edit-schedule-modal": <EditScheduleModal {...{isOpen, modalParams, setOpen}}/>,
				"edit-teacher-profile-modal": <EditTeacherProfileModal {...{isOpen, modalParams, setOpen}}/>,
				"connect-student-modal": <ConnectStudentModal {...{isOpen, modalParams, setOpen}}/>,
				"check-conf-modal": <CheckConfModal {...{isOpen, modalParams, setOpen}}/>,
				// "blocker-router-modal": <BlockerRouterModal {...{isOpen, modalParams, setOpen}}/>,
			}[isOpen]}
		</div>
	)
}
