import { useMutation, useQueryClient } from "@tanstack/react-query"
import { profile } from "api/api"

export const useRemoveCard = () => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: () => profile.removeCard(),
    onSuccess: () => {
      queryClient.invalidateQueries(['profile'])
    },
  })

	return mutation
}