import { IconAlertCircleFilled } from '@tabler/icons-react';
import s from './classCard.module.scss'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

export const PaymentAlert = () => {
	return (
		<div className={classNames(s.paymentAlert)}>
			<div className={s.title}>
				<IconAlertCircleFilled size={20} className={s.icon}/>
				Карта не привязана
			</div>
			<small>
				Чтобы начать проводить занятия, необходимо привязать карту, на которую будет приходить оплата за уроки.
			</small>
			<NavLink className={s.button} to="/settings">Привязать</NavLink>
		</div>
	)
}