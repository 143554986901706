import { useState } from 'react';
import s from './chat.module.scss'
import { Upload } from './upload';
import { getFileTypeIcon } from 'helpers/fileTypesIcons';
import { IconMoodSmile, IconSend, IconX } from '@tabler/icons-react';
import { formatFileSize } from 'helpers/formatFileSize';
import TextareaAutosize from 'react-textarea-autosize';
import EmojiPicker from 'emoji-picker-react';

export const SendBlock = ({handleEnter, text, emojiShow, isLoading, setEmojiShow, setText, file, setFile}) => {
	const [progress, setProgress] = useState(0);
	
	const onUpload = (files) => {
    setFile(files)
  }

	const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Предотвращаем переход на новую строку
      handleEnter(); // Вызываем функцию отправки сообщения
    }
  };

	return (
    <>
      {progress !== 0 && progress}
      <div className={s.inputWrapper}>
        <Upload {...{ onUpload, setProgress, file }} />
        <TextareaAutosize
          onChange={(v) => setText(v.target.value)}
          onKeyDown={handleKeyDown}
          value={isLoading ? '' : text}
          maxRows={5}
          placeholder="Напишите сообщение..."
        />
        <button
          type="submit"
          className={s.emojiBtn}
          onClick={() => setEmojiShow((prev) => !prev)}
        >
          <div className={s.emojiPicker}>
            <EmojiPicker
              onEmojiClick={(e) => setText((prev) => prev + e.emoji)}
            />
          </div>
          <IconMoodSmile />
        </button>
        <button type="submit" className={s.sendBtn} onClick={handleEnter}>
          <IconSend />
        </button>
      </div>
      {(!isLoading && file) && (
        <div className={s.fileBlock}>
          <div className={s.fileIcon}>
            {getFileTypeIcon(file.name.split(".").pop())}
          </div>
          <div className={s.fileInfo}>
            <p className={s.fileName}>{file.name}</p>
            <p className={s.fileSize}>{formatFileSize(file.size)}</p>
          </div>
          <button className={s.fileClearBtn} onClick={() => setFile(null)}>
            <IconX />
          </button>
        </div>
      )}
    </>
  );
}