import s from "./teacher-modal.module.scss";
import { Loader } from "./loader";
import { Modal } from "shared/modal/modal";
import { IconCheck, IconShare2, IconVideo } from "@tabler/icons-react";
import { Button } from "shared/button/button";
import { useNavigate } from "react-router-dom";
import { Reviews } from "shared/reviews/reviews";
import { getNoun } from "helpers/getNoun";
import { useTeacher } from "hooks/useTeacher";
import { useMedia } from "use-media";
import { Loader as Spinner } from 'shared/loader/loader';
import { TextCollapse } from "../../../shared/text-collapse/text-collapse";
import { useState } from "react";
import { VideoViewer } from "../../../shared/video-viewer/video-viewer";
import { getFullName } from "../../../../helpers/getFullname";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export const TeacherModal = ({ isOpen, setOpen, modalParams }) => {
	const teacherId = modalParams?.teacherId
	const {data: teacher, isLoading} = useTeacher(teacherId)
  const navigate = useNavigate();
	
	const isMobile = useMedia({maxWidth: '768px'})

	const [isOpenVideo, setIsOpenVideo] = useState(false)

  return (
		<>
			<VideoViewer isOpen={isOpenVideo} setOpen={setIsOpenVideo} url={teacher?.video_presentation}/>	
			<Modal {...{ isOpen, setOpen }} name={"teacher-modal"}>
				<div className={s.card}>
					{!isLoading ? (
						<>
							<div className={s.baseInfo}>
								<div className={s.avatar}>
									<img
										src={
											teacher?.avatar
												? process.env.REACT_APP_STORAGE_URL + teacher?.avatar.image
												: process.env.REACT_APP_STORAGE_URL + "images/default_avatar.png"
										}
										alt=""
									/>
								</div>
								<div className={s.baseInfoContent}>
									<h5 className={s.name}>
									{getFullName(teacher)}
									</h5>
									{teacher?.video_presentation && <button className={s.videoBtn} onClick={() => setIsOpenVideo(true)}><IconVideo size={18}/> Посмотреть видеообращение</button>}
									<div className={s.infos}>
										<p>🌟 {teacher?.rating}</p>
										<p>📖 {teacher?.appointments_count}{getNoun(teacher?.appointments_count, " занятие", " занятия", " занятий")}</p>
										<p>
											💬 {teacher?.reviews?.length}{" "}
											{getNoun(teacher?.reviews?.length, "отзыв", "отзыва", "отзывов")}
										</p>
									</div>
								</div>
							</div>
							<div className={s.row}>
								<div className={s.col}>
									<div className={s.moreInfo}>
										<div className={s.instruments}>
											{teacher?.instruments?.map((i) => (
												<div className={s.instrumentTag}>{i.name}</div>
											))}
										</div>
										{teacher?.teacher_education?.filter(n => n).length ? <div className={s.education}>
											<span className={s.educationLabel}>Образование и опыт</span>
												{teacher?.teacher_education?.map((e) => (
													<span className={s.educationContent}>
														<IconCheck className={s.icon} />
														{e}
													</span>
												))}
										</div> : null}
										<div className={s.desc}>
											<span className={s.descLabel}>О себе</span>
											<TextCollapse maxHeight={91} className={s.descContent}>
												{teacher?.teacher_bio}
											</TextCollapse>
										</div>
									</div>
								</div>
								<div className={s.col}>
									<Reviews reviews={teacher?.reviews} rating={teacher?.rating} />
								</div>
							</div>
							<div className={s.btns}>
								<CopyToClipboard text={'https://sozvuchno.ru/techer/' + teacher?.id} onCopy={() => toast.success('Ссылка скопирована')}>
									<Button className={s.shareBtn} label={<IconShare2 size={16}/>} theme="secondary" size="small"/>
								</CopyToClipboard>
								<Button
									label="Записаться на занятие"
									className={s.btn}
									onClick={() => {
										setOpen();
										navigate("/teacher/" + teacher?.id);
									}}
								/>
							</div>
						</>
					) : isMobile ? <Spinner/> : <Loader />}
				</div>
			</Modal>
		</>
  );
};
