import React from 'react'
import s from './not-found.module.scss'

export const NotFound = () => {
	return (
		<div className={s.wrapper}>
			<img src="./404.svg" alt="" />
			<p>Такой страницы мы еще не придумали(</p>
		</div>
	)
}
