export const isImage = (attachment) => {
	const type = attachment?.name.split('.').pop().toLowerCase()
	return type === 'png' || type === 'jpeg' || type === 'jpg'
}

export const isAudio = (attachment) => {
  const type = attachment?.name.toLowerCase().split('.').pop();
  return ['mp3', 'wav', 'ogg', 'aac', 'flac'].includes(type);
};


export const isPDF = (attachment) => {
  const type = attachment?.name.toLowerCase().split('.').pop();
  return type === 'pdf'
};