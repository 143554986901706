import s from './add-card-success.module.scss'
import { Button } from 'shared/button/button';
import { IconCreditCard } from '@tabler/icons-react';

export const AddCardSuccess = () => {
	return (
		<div className={s.wrapper}>
			<div className={s.card}>
				<div className={s.content}>
					<IconCreditCard size={100} className={s.icon}/>
					<div className={s.title}>Карта успешно привязана!</div>
					<div className={s.subtitle}>Теперь ваша анкета будет видна другим пользоватлям в поиске.</div>
				</div>
				<div className={s.buttons}>
					<Button theme='secondary' label='Вернуться к настройкам' to={'/settings'} className={s.btn}/>
				</div>
			</div>
		</div>
	)
}
