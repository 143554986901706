import React, { useCallback, useContext, useState } from 'react'
import s from './appointment-note.module.scss'
import { IconFile, IconPaperclip, IconPencil, IconTrash } from '@tabler/icons-react'
import { useUpdateAppointmentNote } from '../../../hooks/useUpdateAppointmentNote';
import { Button } from '../../shared/button/button';
import { useDropzone } from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';
import { ModalsContext } from '../../layouts/dashboard-layout/dashboard';
import classNames from 'classnames';
import { AppointmentContentBlock } from '../../shared/appointment-content-block/appointment-content-block';

const EditNote = ({note, onSuccess, appointmentId}) => {
	const [text, setText] = useState(note?.text)
	const [myFiles, setMyFiles] = useState(note?.attachments)
	const [isLoading, setIsLoading] = useState(false)

	const {mutate: editNote} = useUpdateAppointmentNote(appointmentId, () => {
		setIsLoading(false)
		onSuccess()
	})

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])


	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
		maxFiles: 5,
		onDrop,
		maxSize: 104857600
	});

  const removeFiles = (name) => {
    setMyFiles(prev => prev.filter(el => el.name !== name))
  }

	const onClick = () => {
		setIsLoading(true)
		editNote({appointmentId, noteId: note.id, 
			data: {
				text
			},
			attachments: acceptedFiles,
			filesId: myFiles?.map(el => el.id)
		})
	}
	const onCancel = () => {
		onSuccess()
	}

  return (
    <div className={classNames(s.addNoteCard, isLoading ? s.isLoading : '')}>
      <TextareaAutosize
        onChange={v => setText(v.target.value)}
        value={text}
				maxRows={5}
        placeholder="Начните печатать здесь...."
      ></TextareaAutosize>
			{myFiles.length >= 1 && 
				myFiles.map(el => (
					<div className={s.fileWrapper}>
						<p>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{el.name} - {el.size}
						</p>
						<button className={s.removeBtn} onClick={() => removeFiles(el.name)}>
							<IconTrash size={18} color={'#F12828'}/>
						</button>
					</div>
				))}
			 <div className={s.jcsb}>
					<Button
						label="Отменить"
						size="small"
						theme={"secondary"}
						onClick={onCancel}
					/>
					<div className={s.btns}>
						<input {...getInputProps()} />
						<Button label={<IconPaperclip size={16}/>} {...getRootProps()} size="small" theme='secondary' className={s.attachmentButton}/>
						<Button label="Сохранить" size="small" onClick={onClick} disabled={!text}/>
					</div>
				</div>
    </div>
  );
};

export const AppointmentNote = ({note, appointmentId}) => {
	const [isEditable, setIsEditable] = useState(false)
	const { setModal } = useContext(ModalsContext)

	if(isEditable){
		return (
			<EditNote {...{note, appointmentId}} onSuccess={() => setIsEditable(false)}/>
		)
	}

	return (
		<div className={s.contentBlock}>
			<AppointmentContentBlock attachments={note?.attachments} text={note?.text}/>
			<div className={s.btns}>
				<button className={s.editBtn} onClick={() => setIsEditable(true)}><IconPencil/></button>
				<button className={s.removeBtn} onClick={() => setModal('remove-appointments-note', {appointmentId, noteId: note.id})}><IconTrash/></button>
			</div>
		</div>
	)
}