import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {setAuthToken} from "helpers/tokens";
import {toast} from "react-toastify";
import { getTimezone } from '../helpers/getTimezone';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const errorHandler = (error) => {
	if(error?.response?.status && error?.response?.status !== 403 && error?.response?.status !== 404 && error?.response?.status !== 500){
		toast.error(`${error?.response?.data?.message || 'Возникла ошибка'}`);  
	}

  return Promise.reject({ ...error })
}

instance.interceptors.response.use(
  (response) => {return response},
  (error) => errorHandler(error)
);


function getAccessToken() {
	return localStorage.getItem('token');
}

instance.interceptors.request.use((request) => {
	if (!request.headers['Authorization']){
		request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
	}
	return request;
});

const refreshAuthLogic = async failedRequest => {
	return instance.post('/api/refresh').then((tokenRefreshResponse) => {
		localStorage.setItem('token', tokenRefreshResponse.data.authorisation.token)
		const newInstance = axios.create({
			baseURL: process.env.REACT_APP_API_URL,
			headers: {
				Authorization: `Bearer ${tokenRefreshResponse.data.authorisation.token}`
			}
		});
		failedRequest.response.config.httpAgent = newInstance;
	}).catch(err => {
		localStorage.removeItem('token')
	})
};

createAuthRefreshInterceptor(instance, refreshAuthLogic, {statusCodes: [403, 401]});

export const auth = {
	login(loginPayload) {
		return instance.post("/api/login", loginPayload)
			.then(response => {
				const token  =  response.data.authorisation.token;
				localStorage.setItem("token", token);
				setAuthToken(token);
				window.location.href = '/'
			})
	},
	loginVk(loginPayload) {
		return instance.post("/api/login/vk", loginPayload)
			.then(response => {
				const token  =  response.data.authorisation.token;
				localStorage.setItem("token", token);
				setAuthToken(token);
				window.location.href = '/'
			})
	},
	loginYandex(loginPayload) {
		return instance.post("/api/login/yandex", loginPayload)
			.then(response => {
				const token  =  response.data.authorisation.token;
				localStorage.setItem("token", token);
				setAuthToken(token);
				window.location.href = '/'
			})
	},
	forgotPassword(data) {
		return instance.post("/api/forgot-password", data)
	},
	resetPassword(data) {
		return instance.post("/api/reset-password", data)
	},
	verifyEmail(token) {
		return instance.post("/api/verify-email/" + token)
	},
	register(payload) {
		return instance.post("/api/register", payload)
	},
	registerTeacher(payload) {
		return instance.post("/api/register/teacher", payload)
	},
	me() {
		return instance.get("/api/me").then(response => {
			if(response?.data && response.data?.timezone !== getTimezone()){
				instance.post("/api/profile/update", {
					"timezone": getTimezone()
				})
			}
			
			return response
		})
	},
	logout() {
		localStorage.removeItem("token");
		setAuthToken();
		window.location.href = '/login'
	}
}

export const profile = {
	getAllInstruments() {
		return instance.get('/api/instruments/all')
	},
	getAnalytics() {
		return instance.get('/api/profile/analytics')
	},
	updateProfileAvatar(data) {
		const formData = new FormData()
		formData.append('avatar', data);
	
		return instance.post('/api/profile/update-avatar', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	updateProfile(data) {
		return instance.post("/api/profile/update", {...data})
	}, 
	updatePassword(data) {
		return instance.post("/api/profile/update-password", {...data})
	}, 
	updateProfileNotifications(data){
		return instance.post("/api/profile/update-notifications", {...data})
	},
	updateVideoPresentation(data) {
		const formData = new FormData()
		formData.append('video_presentation', data);
	
		return instance.post('/api/profile/update-video-presentation', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		
	},
	fillInformation(data) {
		return instance.post("/api/profile/fill-up", data)
			.then(response => {
				const token = response.data.authorisation.token;
				localStorage.setItem("token", token);
				setAuthToken(token);
				window.location.href = '/'
			}).catch(err => {
				toast.error(err?.response?.data?.message)
			})
	},
	addCard() {
		return instance.get('https://secure.sozvuchno.ru/add-card')
	},
	removeCard() {
		return instance.post('https://secure.sozvuchno.ru/remove-card')
	},
	getAllTeachers(params) {
		return instance.get('/api/teachers/search', {params: {...params}})
	},
	getTimeSlots() {
		return instance.get('/api/profile/timeslots')
	},
	updateTimeSlots(data) {
		return instance.post('/api/profile/timeslots/update', data)
	},
	detachTelegram() {
		return instance.post('/api/profile/detach-telegram')
	},
}

const  uploadFiles = async (files) => {
	let uploadPromises = files?.map(file => {
			const formData = new FormData();
			formData.append('attachment', file);

			let toastId = null
			return instance.post('/api/attachments/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (processEvent) => {
					const {loaded, total} = processEvent
					let progress = loaded / total
					let percents = Math.floor(progress * 100)

					if (toastId === null) {
						toastId = toast('Загрузка файла ' + percents + '%', { type: 'success', progress, isLoading: percents < 100 });
					} else {
						toast.update(toastId, { render: 'Загрузка файла ' + percents + '%', progress });
					}
				}
			}).finally(() => {
				if (toastId !== null) {
					toast.dismiss(toastId);
				}
			});
	});

	const uploadResponses = await Promise.all(uploadPromises);
	const attachments = uploadResponses?.map(response => response.data.id); // id of attachments

	return attachments
}

export const appointment = {
	async createNote(data){
		const attachments = await uploadFiles(data?.attachments)

		return instance.post(`/api/appointments/${data.appointmentId}/notes/create`, {
			text: data.data.text,
			attachments
		})
	},
	getNote(data){
		return instance.get(`/api/appointments/${data.appointmentId}/notes/${data.noteId}`)
	},
	async editNote(data){
		const attachments = await uploadFiles(data?.attachments)

		return instance.post(`/api/appointments/${data.appointmentId}/notes/${data.noteId}/edit`, {
			text: data.data.text,
			attachments: [...data?.filesId, ...attachments]
		})
	},
	removeNote(data){
		return instance.delete(`/api/appointments/${data.appointmentId}/notes/${data.noteId}/delete`)
	},
	async createHomework(data){
		const attachments = await uploadFiles(data?.attachments)

		return instance.post(`/api/appointments/${data.appointmentId}/homework/create`, {
			task: data.data.task,
			attachments
		})
	},
	getHomework(data){
		return instance.get(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}`)
	},
	async editHomework(data){
		const attachments = await uploadFiles(data?.attachments)
		
		return instance.post(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}/edit`, {
			task: data.data.task,
			attachments: [...data?.filesId, ...attachments]
		})
	},
	getRecord(id){
		return instance.get(`/api/appointments/${id}/download`)
	},
	removeHomework(data){
		return instance.delete(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}/delete`)
	},
	async submitHomework(data){
		const attachments = await uploadFiles(data?.attachments)

		return instance.post(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}/submissions/submit`, {
			text: data.data.text,
			attachments
		})
	},
	getSubmission(data){
		return instance.get(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}/submissions/${data.submissionId}`)
	},
	rejectHomework(data){
		return instance.post(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}/submissions/${data.submissionId}/reject`, {
			comment: data.comment
		})
	},
	approveHomework(data){
		return instance.post(`/api/appointments/${data.appointmentId}/homework/${data.homeworkId}/submissions/${data.submissionId}/approve`)
	},

}

export const classes = {
	getClassById(id) {
		return instance.get(`/api/appointments/${id}`)
	},
	getClassTokenById(id) {
		return instance.get(`/api/appointments/${id}/join-token`)
	},
	getAllClasses(params) {
		return instance.get('/api/appointments', {params: params})
	},
	getUpcomingClasses() {
		return instance.get('/api/appointments/upcoming')
	},
	getHistory(page){
		return instance.get('/api/appointments/history', {params: {page}})
	},
	cancelClass({id, reason}){
		return instance.post('/api/appointments/cancel', {
				appointment_id: id,
				reason: reason
		})
	},
	payForAppointment(id) {
		return instance.get('https://secure.sozvuchno.ru/pay/appointment/' + id)
	},
	createClass(teacherId, time, date, instrument) {
		return instance.post('/api/appointments/create', {
			"teacher_id": teacherId,
			"date": date,
			"time": time,
			"duration": "1h",
			"instrument_id": instrument
		})
	},
	getTeacher(id) {
		return instance.get(`/api/teachers/${id}`)
	},
	getReviews(id) {
		return instance.get(`/api/teachers/${id}/feedbacks`)
	},
	addReview(body, id) {
		return instance.post(`/api/appointments/${id}/feedback`, body)
	}
}

export const chat = {
	postAttachment(data) {
		const formData = new FormData()
		formData.append('attachment', data);
	
		let toastId = null
		return instance.post('/api/attachments/upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: (processEvent) => {
					const {loaded, total} = processEvent
					let progress = loaded / total
					let percents = Math.floor(progress * 100)

					if (toastId === null) {
						toastId = toast('Загрузка файла ' + percents + '%', { type: 'success', progress, isLoading: percents < 100 });
					} else {
						toast.update(toastId, { render: 'Загрузка файла ' + percents + '%', progress });
					}
				}
			}).finally(() => {
				if (toastId !== null) {
					toast.dismiss(toastId);
				}
		})
	}
}

export const downloadDocApi = (url) => {
	
	return instance.get(url, { responseType: 'blob' })
}

export const students = {
	getMyStudents(params) {
		return instance.get('/api/profile/students', {params: params})
	},
	getStudentById(id){
		return instance.get('/api/profile/students/' + id)
	},
	getIsStudentOnService(email){
		return instance.get('/api/profile/students/check-on-service', {data: {email}})
	},
	connectStudent(data){
		return instance.post('/api/profile/students/invite', data).then(res => {
			if(!res?.data?.student_id){
				toast.success('Приглашение отправлено')
			}
			
			return res
		})
	},
	updateStudent(data){
		return instance.post('/api/profile/students/'+ data.id +'/update', {outside_payments: data.outside_payments})
	},
	disconnectStudentById(id){
		return instance.delete('/api/profile/students/' + id)
	},
	isOutsidePayments(id){
		return instance.get('/api/teachers/' + id + '/outside-payment-available')
	}
}