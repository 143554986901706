import React, { useEffect, useRef } from 'react'
import s from './post-payment.module.scss'
import { Button } from 'shared/button/button';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import ReactConfetti from 'react-confetti';
import { useTeacher } from 'hooks/useTeacher';
import { useWindowSize } from 'hooks/useWindowSize';
import { useMedia } from 'use-media';
import { useOutsidePayments } from '../../../hooks/useOutsidePayments';
import { Loader } from '../../shared/loader/loader';


export const PostPayment = ({appointment}) => {
	const {data: teacher} = useTeacher(appointment.teacher_id)
	const {data: isOutsidePayments, isFetched} = useOutsidePayments(appointment?.teacher_id)
	const date = new Date(appointment.starts_at)
	const wrp = useRef()
	const { width, height } = useWindowSize()

	const isTablet = useMedia({maxWidth: '1312px'})

	return (
		<div className={s.wrapper} ref={wrp}>
			<div className={s.card}>
				<div className={s.content}>
					<IconCircleCheckFilled size={100} className={s.icon}/>
					{isFetched ? isOutsidePayments?.available ? <div className={s.title}>Занятие забронировано!</div> : <div className={s.title}>Оплата прошла!</div> : <Loader/>}
					<div className={s.info}>
						<div className={s.infoItem}>
							<div className={s.infoLabel}>Преподаватель</div>
							<div className={s.infoValue}>{teacher?.name + " " + (teacher?.lname || '')}</div>
						</div>
						<div className={s.infoItem}>
							<div className={s.infoLabel}>Дата</div>
							<div className={s.infoValue}>{date.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit'})}</div>
						</div>
						<div className={s.infoItem}>
							<div className={s.infoLabel}>Продолжительность</div>
							<div className={s.infoValue}>50 мин.</div>
						</div>
					</div>
				</div>
				<div className={s.buttons}>
					<Button theme='secondary' label='Вернуться на главную' to={'/'} className={s.btn}/>
					<Button label='Записаться еще' to={`/teacher/${appointment?.teacher_id}`} className={s.btn}/>
				</div>
			</div>
			<ReactConfetti height={isTablet ? height - 63 : height} width={width} recycle={false} numberOfPieces={2000} />
		</div>
	)
}
