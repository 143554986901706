import React, { useContext } from 'react'
import s from './students-list.module.scss'
import classNames from 'classnames'
import { Button } from '../../shared/button/button'
import { IconPlus } from '@tabler/icons-react'
import { ModalsContext } from '../../layouts/dashboard-layout/dashboard'
import { getFullName } from '../../../helpers/getFullname'

export const StudentsList = ({students, className}) => {
	const {setModal} = useContext(ModalsContext)

	return (
		<div className={classNames(className, s.card)}>
			<div className={s.title}>
				Мои студенты 
				<Button className={s.addBtn} label={<IconPlus size={16}/>} size="small" onClick={() => setModal('connect-student-modal')}/>
			</div>
		{students?.length > 0 ? 
		<>
			<div className={s.scroller}>
				<div className={s.list}>
					{students?.map(({student, student_id, outside_payments, id}) => {
						if(!student_id){
							return null
						}
						
						return (
							<div onClick={() => setModal('student-modal', {studentId: id})} className={s.studentCard}>
									<div className={s.avatar}>
										<img src={student?.avatar ?process.env.REACT_APP_STORAGE_URL + student?.avatar?.image : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png'} alt="" />
									</div>
									<div className={s.content}>
										<div className={s.name}>{getFullName(student)}</div>
										{outside_payments && <div className={s.payment}>
											оплата вне сервиса
										</div>}
									</div>
							</div>
						)
					})} 
				</div>
			</div>
		</>
		
		: <div className={s.notFound}>У вас пока нет студентов</div>}
		
	</div>)
}