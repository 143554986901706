import s from './blocker-router-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { Button } from 'shared/button/button';

export const BlockerRouterModal = ({isOpen, setOpen, modalParams}) => {
	const props = modalParams?.blockerProps

  return (
		<Modal {...{isOpen, setOpen}} name={'blocker-router-modal'}>
			<div className={s.card}>
					<div className={s.title}>Вы хотите перейти на другую страницу?</div>
					<div className={s.text}>Если вы покинете страницу, конференция прервется</div>
					<div className={s.btns}>
						<Button
							theme='secondary'
							className={s.btn}
							onClick={() => {
								props?.onConfirm()
							}}
							label={'Перейти'}
						/>
						<Button
							className={s.btn}
							onClick={() => {
								props?.onCancel()
							}}
							label={'Остаться'}
						/>
					</div>
			</div>
		</Modal>
  );
};