import s from './appointment-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { Loader } from 'shared/loader/loader';
import { useAppointment } from 'hooks/useAppointment';
import { useProfile } from 'hooks/useProfile';
import { getNoun } from 'helpers/getNoun';
import { Button } from 'shared/button/button';
import { IconMessage } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../../helpers/getFullname';
import { classes } from 'api/api';
import { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

export const AppointmentModal = ({isOpen, setOpen, modalParams}) => {
	const [canCancel, setCanCancel] = useState(false)
	
	const appointmentId = modalParams?.appointmentId
	const {data: appointment, isFetching} = useAppointment(appointmentId)
	const {data: profile} = useProfile()

	const teacher =  appointment?.teacher
	const student =  appointment?.student
	const isTeacher =  profile?.role === 'teacher'

	const navigate = useNavigate()

	useEffect(() => {
    const appointmentDate = new Date(appointment?.starts_at);
    const today = new Date();
    const diffDays = differenceInDays(appointmentDate, today);

    if (diffDays > 0) {
      setCanCancel(true);
    } else {
      setCanCancel(isTeacher);
    }
  }, [appointment, isTeacher]);

  return (
		<Modal {...{isOpen, setOpen}} name={'appointment-modal'} title={'Занятие'}>
			<div className={s.card}>
				{isFetching ? <Loader/> : 
					<div className={s.wrapper}>
						<div className={s.baseInfo}>
							{profile?.role === 'student' ? 
								<>
										<div className={s.avatar}>
											<img
												src={
													teacher?.avatar
														? process.env.REACT_APP_STORAGE_URL + teacher?.avatar.image
														: process.env.REACT_APP_STORAGE_URL + "images/default_avatar.png"
												}
												alt=""
											/>
										</div>
										<div className={s.baseInfoContent}>
											<h5 className={s.name}>
												{getFullName(teacher)}
											</h5>
											<div className={s.infos}>
												<p>🌟 {teacher?.rating}</p>
												<p>📖 {teacher?.appointments_count}{getNoun(teacher?.appointments_count, " занятие", " занятия", " занятий")}</p>
												<p>
													💬 {teacher?.reviews?.length}{" "}
													{getNoun(teacher?.reviews?.length, "отзыв", "отзыва", "отзывов")}
												</p>
											</div>
										</div>
									</> :
									<>
										<div className={s.avatar}>
											<img
												src={
													student?.avatar
														? process.env.REACT_APP_STORAGE_URL + student?.avatar.image
														: process.env.REACT_APP_STORAGE_URL + "images/default_avatar.png"
												}
												alt=""
											/>
										</div>
										<div className={s.baseInfoContent}>
											<h5 className={s.name}>
												{getFullName(student)}
											</h5>
										</div>
									</>
								}
							{appointment?.payed ? <Button label={'Написать'} lefticon={<IconMessage/>} size='small' className={s.messageBtn} onClick={() => {
									navigate('/messenger', {state: {id_user_in_chat: profile?.role === 'student' ? teacher?.id : student?.id}})
									setOpen()
							}}/> : ''}
						</div>
						<div className={s.classInfo}>
							<div className={s.infoItem}>
								<div className={s.infoLabel}>Специальность</div>
								<div className={s.infoValue}>{appointment?.instrument.name}</div>
							</div>
							<div className={s.infoItem}>
								<div className={s.infoLabel}>Начало</div>
								<div className={s.infoValue}>{(new Date(appointment?.starts_at)).toLocaleDateString('RU-ru', {
									day: 'numeric',
									month: 'long',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit'
								})}</div>
							</div>
							<div className={s.infoItem}>
								<div className={s.infoLabel}>Длительность</div>
								<div className={s.infoValue}>50 мин.</div>
							</div>
						</div>
						<div className={s.btns}>
							{appointment?.status === 'active' ? <Button disabled={!canCancel} theme='secondary' label={'Отменить занятие'} className={s.cancelBtn} onClick={() => setOpen('remove-appointment', {appointmentId: appointment?.id})}/> : null}
							{(appointment?.payed || profile?.role === 'teacher') ? <Button label={'Перейти к занятию'} onClick={() => {
									navigate('/appointment/' + appointment?.id)
									setOpen()
								}}/> : 
								<Button label={'Оплатить'} onClick={() => {
									classes.payForAppointment(appointment?.id).then(payment => {
										window.open(payment.data, "_self")
									})
									setOpen()
								}}/>}
						</div>
						{!isTeacher && <small>
							*Занятие можно отменить не позже чем за день до начала занятия.
						</small>}
					</div>
					}
			</div>
		</Modal>
  );
}