import React, { useCallback, useEffect, useState } from 'react'
import s from './add-note-card.module.scss'
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '../../shared/button/button';
import { IconFile, IconPaperclip, IconTrash } from '@tabler/icons-react';
import { formatFileSize } from '../../../helpers/formatFileSize';
import { useDropzone } from 'react-dropzone';
import { useAddAppointmentNote } from '../../../hooks/useAddAppointmentNote';
import classNames from 'classnames';
import { VoiceRecorder } from '../../shared/voice-recorder/voice-recorder';
import { blobToFile } from '../../../helpers/blobToFile';

export const AddNoteCard = ({appointmentId}) => {
	const [text, setText] = useState(null)
	const [myFiles, setMyFiles] = useState([])
	const [blob, setBlob] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const {mutate: createNote} = useAddAppointmentNote(appointmentId, 
		() => {
			setText('')
			setMyFiles([])
			setIsLoading(false)
			setBlob(null)
		})

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])

	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
		maxFiles: 5,
		onDrop,
		maxSize: 104857600
	});

  const removeFiles = (name) => {
    setMyFiles(prev => prev.filter(el => el.name !== name))
  }

	const onClick = () => {
		setIsLoading(true)

		createNote({appointmentId, 
			data: {
				text
			},
			attachments: blob ? [...acceptedFiles, blob] : acceptedFiles
		})
	}

  return (
    <div className={classNames(s.addNoteCard, isLoading ? s.isLoading : '')}>
			<div className={s.textareaWrapper}>
				<TextareaAutosize
					onChange={v => setText(v.target.value)}
					value={text}
					maxRows={5}
					placeholder="Начните печатать здесь...."
				></TextareaAutosize>
			</div>
			{myFiles.length >= 1 && 
				myFiles.map(el => (
					<div className={s.fileWrapper}>
						<p>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{el.name} - {formatFileSize(el.size)}
						</p>
						<button className={s.removeBtn} onClick={() => removeFiles(el.name)}>
							<IconTrash size={18} color={'#F12828'}/>
						</button>
					</div>
				))}
				<div className={s.btns}>
					<input {...getInputProps()} />
					<VoiceRecorder setBlob={setBlob} blob={blob} onRecordingComplete={(res) => {
						!res ? setBlob(null) :
						setBlob(blobToFile(res, (new Date()).toDateString() + '.mp3'))
					}}/>
      		<Button label={<IconPaperclip size={16}/>} {...getRootProps()} size="small" theme='secondary' className={s.attachmentButton}/>
      		<Button label="Добавить заметку" size="small" className={s.addButton} onClick={onClick} disabled={!text}/>
				</div>
    </div>
  );
};

