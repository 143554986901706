import React, { useContext } from 'react'
import s from './settings.module.scss'
import { IconCreditCard, IconEdit } from '@tabler/icons-react'
import { Button } from 'shared/button/button'
import { ModalsContext } from '../../../components/layouts/dashboard-layout/dashboard'
import { useProfile } from 'hooks/useProfile'
import { Notifications } from 'shared/notification-settings/notification'
import { auth } from 'api/api';
import { SettingsForm } from '../../../components/shared/settings-form/settings-form'
import { UpdatePassword } from '../../../components/shared/update-password/update-password'
import { profile as profileApi } from 'api/api';
import { useRemoveCard } from '../../../hooks/useRemoveCard';
import { NavLink } from 'react-router-dom'

export const TeacherSettings = () => {
	const {setModal} = useContext(ModalsContext)

	const {data: profile} = useProfile()
	const {mutate: removeCard} = useRemoveCard()

	const addCard = () => {
		profileApi.addCard().then(res => {
			window.open(res.data, "_self")
		})
	}

	return (
		<div className={s.wrapper}>
			<div className={s.title}>Настройки</div>
			<div className={s.flex}>
				<div className={s.card}>
					<div className={s.profile}>
						<div className={s.avatar}>
							<button className={s.edit} onClick={() => setModal('update-avatar-modal')}>
								<IconEdit size={24} color='#fff'/>
							</button>
							<img src={profile.avatar ? process.env.REACT_APP_STORAGE_URL + profile.avatar.image : process.env.REACT_APP_STORAGE_URL + 'images/default_avatar.png'} alt="" />
						</div>
						<div className={s.profileContent}>
							<div className={s.name}>{profile?.name}</div>
							<div className={s.role}>{profile?.email}</div>
						</div>
						<NavLink className={s.viewProfile} onClick={() => setModal('edit-teacher-profile-modal')}>Посмотреть анкету</NavLink>
					</div>
					<div className={s.paymentWrapper}>
						{profile?.card_num_masked ? 
						<>
							<div className={s.cardNumber}>
								{profile?.card_num_masked.replace(/(.{4})/g, '$1 ').trim()}
								<IconCreditCard color='#ccc'/>
							</div>
							<Button onClick={removeCard} theme="secondary" label="Отвязать карту" className={s.fullBtn} />
						</>
						: 
						<>
							<Button onClick={addCard} label="Привязать карту" className={s.fullBtn} />
							<div className={s.paymentSubtitle}>
								По этим реквизитам будет приходить оплата за уроки
							</div>
						</>}
					</div>
					<SettingsForm/>
					<UpdatePassword/>
				</div>
				<div className={s.col}>
					<Notifications/>
					<div className={s.card}>
						<button className={s.logout} onClick={() => auth.logout()}>Выйти</button>
					</div>
				</div>
			</div>
		</div>
	)
}