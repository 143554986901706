import s from './student-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { Loader } from 'shared/loader/loader';
import { Button } from 'shared/button/button';
import { IconMessage } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../../helpers/getFullname';
import { useStudent } from '../../../../hooks/useStudent';
import { useUpdateStudent } from '../../../../hooks/useUpdateStudent';
import { ToggleSimple } from '../../../shared/ToggleSimple/ToggleSimple';
import { useEffect, useState } from 'react';
import { useDisconnectStudent } from '../../../../hooks/useDisconnectStudent';

export const StudentModal = ({isOpen, setOpen, modalParams}) => {
	const [outsidePayments, setOutsidePayments] = useState(null);
	const studentId = modalParams?.studentId
	const {data: studentData, isLoading} = useStudent(studentId)
	const {mutate: disconnect} = useDisconnectStudent()

	const navigate = useNavigate()
	const {mutate: updateStudent} = useUpdateStudent()

  const updatePayment = (data) => {
		const payload = {
			outside_payments: data.outside_payments,
			id: studentId
		}
		
		updateStudent(payload)
  }

	useEffect(() => {
    if(!outsidePayments && studentData?.ts?.outside_payments !== undefined) {
      setOutsidePayments(studentData?.ts?.outside_payments);
    }
  }, [studentData]);

	useEffect(() => {
    if(typeof outsidePayments === 'boolean' && studentData?.ts?.outside_payments !== outsidePayments) {
      updatePayment({outside_payments: outsidePayments});
    }
  }, [outsidePayments]);

  return (
		<Modal {...{isOpen, setOpen}} name={'student-modal'}>
			<div className={s.card}>
				{isLoading ? <Loader/> : 
					<div className={s.wrapper}>
						{/* "earned_total": 0,
    "earned_last_month": 0,
    "lessons_conducted": 10 */}
						<div className={s.baseInfo}>
							<div className={s.avatar}>
								<img
									src={
										studentData?.ts?.student?.avatar
											? process.env.REACT_APP_STORAGE_URL + studentData?.ts?.student?.avatar.image
											: process.env.REACT_APP_STORAGE_URL + "images/default_avatar.png"
									}
									alt=""
								/>
							</div>
							<div className={s.baseInfoContent}>
								<h5 className={s.name}>
									{getFullName(studentData?.ts?.student)}
								</h5>
							</div>
							<Button label={'Написать'} lefticon={<IconMessage/>} size='small' className={s.messageBtn} onClick={() => {
									navigate('/messenger', {state: {id_user_in_chat: studentData?.ts?.student?.id}})
									setOpen()
							}}/>
						</div>
						<div className={s.analytics}>
								<div className={s.infoBlock}>
									<div className={s.infoLabel}>
										Заработано всего
									</div>
									<div className={s.infoValue}>
										{studentData.earned_total}₽
									</div>
								</div>
								<div className={s.infoBlock}>
									<div className={s.infoLabel}>
									Заработано за месяц
									</div>
									<div className={s.infoValue}>
										{studentData.earned_last_month}₽
									</div>
								</div>
								<div className={s.infoBlock}>
									<div className={s.infoLabel}>
										Проведено уроков
									</div>
									<div className={s.infoValue}>
										{studentData.lessons_conducted}
									</div>
								</div>
						</div>
						<ToggleSimple label={'Оплата вне сервиса'} onChange={setOutsidePayments} value={outsidePayments} className={s.toggleWrapper}/>
						<div className={s.btns}>
							<button className={s.remove} onClick={() => {
								disconnect(studentId)
								setOpen(null)
							}}>Убрать студента</button>
						</div>
					</div>
					}
			</div>
		</Modal>
  );
}