import { useEffect, useState } from 'react';
import s from './home.module.scss'
import { ClassesBoard } from '../../../components/teacher-profile/classes-board/classes-board';
import { NextClassCard } from 'shared/classCard/nextClassCard';
import { NotNextClassCard } from 'shared/classCard/notNextClassCard';
import { Loader } from 'shared/loader/loader';
import { useAllAppointments } from '../../../hooks/useAllAppointments';
import { useUpcomingAppointments } from '../../../hooks/useUpcomingAppointments';
import { endOfWeek, startOfWeek } from 'date-fns';
import { StudentAnalytics } from '../../../components/shared/student-analytics/student-analytics';

export const DashboardHome = () => {
	const date = new Date()

	const [start_date, set_start_date] = useState();
  const [end_date, set_end_date] = useState();

	const { data: appointments, isFetched: appointmentsIsFetched } =
    useAllAppointments({
      from: start_date?.toISOString(),
      to: end_date?.toISOString(),
    });
	const {data: upcoming, isFetched: isUpcomingFetched} = useUpcomingAppointments()

	const getCurrentWeekDates = () => {
			const from = startOfWeek(new Date(date));
			const to = endOfWeek(new Date(date))

			set_start_date(from);
			set_end_date(to);
	};

	useEffect(() => {
			getCurrentWeekDates();
	}, []);

	return (
		<div className={s.wrapper}>
			<div className={s.flex}>
				<StudentAnalytics/>
				{isUpcomingFetched ? 
					(upcoming ? 
						<NextClassCard className={s.nextClassCard} {...upcoming}/> :
						<NotNextClassCard className={s.nextClassCard}/>) :
					<div className={s.nextClassLoader}>
						<Loader/>
					</div>
				}
			</div>
			<ClassesBoard {...{appointments, set_end_date, set_start_date, appointmentsIsFetched, start_date, end_date}} isTeacher={false}/>
		</div>
	)
}
