import {
	Navigate,
  Outlet,
  Route,
  Routes as RouterDomRoutes,
} from "react-router-dom";

import { LoginPage } from "pages/auth/LoginPage";
import { RegisterPage } from "pages/auth/RegisterPage";
import { DashboardHome } from "pages/dashboard/home/home";
import { Teachers } from "pages/dashboard/teachers/teachers";
import { History } from "pages/dashboard/history/history";
import { VerifyPage } from "pages/auth/VerifyPage";
import { Settings } from "pages/dashboard/settings/settings";
import { TeacherHome } from "pages/teacher-dashboard/home/home";
import { Messenger as TeacherMessenger  } from "pages/teacher-dashboard/messenger/messenger";
import { TeacherSettings } from "pages/teacher-dashboard/settings/settings";
import { Teacher } from "pages/dashboard/teacher/teacher";
import { PostPaymentPage } from "pages/dashboard/post-payment-page/post-payment-page";
import { History as TeacherHistory } from 'pages/teacher-dashboard/history/history';
import { Messenger } from 'pages/dashboard/messenger/messenger';

import { useProfile } from "hooks/useProfile";

import { VkRedirect } from "./components/auth/login/vk-redirect";
import { Loader } from "shared/loader/loader";
import { MainLayout } from './components/layouts/main-layout/main';
import { Dashboard } from "./components/layouts/dashboard-layout/dashboard";
import { AfterRegistration } from "./components/auth/after-registration/after-registration";
import { AfterRegistrationTeacher } from "./components/auth/after-registration-teacher/after-registration-teacher";
import { Appointment } from "./pages/dashboard/appointment/appointment";
import { AppointmentTeacher } from "./pages/teacher-dashboard/appointment-teacher/appointment-teacher";
import { PostPaymentPageFail } from './pages/dashboard/post-payment-page-fail/post-payment-page-fail';
import { WaitingRestore } from "./components/auth/waiting-restore/waiting-restore";
import { ForgetPasswordPage } from './pages/auth/ForgetPasswordPage';
import { ResetPassword } from "./components/auth/reset-password/reset-password";
import { Information } from "./pages/dashboard/information/information";
import { NotFound } from "./pages/not-found/not-found";
import { AddCardSuccessPage } from "./pages/dashboard/add-card-success-page/add-card-success-page";
import { AddCardFailPage } from "./pages/dashboard/add-card-fail-page/add-card-fail-page";

export function Routes() {
	const {data: profile, isSuccess: profileSuccess, isError} = useProfile()

  const hasJWT = () => !!localStorage.getItem("token")

	if(isError){
		localStorage.removeItem('token')
		window.location.reload();
	}

  return (
    <>
      <RouterDomRoutes>
        {hasJWT() ?
				<>
					{profileSuccess ? (
						<>
								{
									{
										student: (
											<Route element={<Dashboard />}>
												<Route
													path="/"
													element={
														<DashboardHome/> //context
													}
												/>
												<Route path="/teachers" element={<Teachers />} />
												<Route path="/teacher/:id" element={<Teacher />} />
												<Route path="/history" element={<History />} />
												<Route path="/settings" element={<Settings />} />
												<Route path="/messenger" element={<Messenger/>} /> 
												<Route path="/info" element={<Information/>} />
												<Route path="/post-payment/:appointmentId" element={<PostPaymentPage/>} />
												<Route path="/post-payment-fail/:appointmentId" element={<PostPaymentPageFail/>} />
												<Route path="/appointment/:appointmentId" element={<Appointment/>} />
											</Route>
										),
										teacher: (
											<Route element={<Dashboard />}>
												<Route path="/" element={<TeacherHome />} />
												<Route path="/messenger" element={<TeacherMessenger />}/>
												<Route path="/history" element={<TeacherHistory />} />
												<Route path="/settings" element={<TeacherSettings />} />
												<Route path="/info" element={<Information/>} />
												<Route path="/add-card-success" element={<AddCardSuccessPage/>} />
												<Route path="/add-card-fail" element={<AddCardFailPage/>} />
												<Route path="/appointment/:appointmentId" element={<AppointmentTeacher/>} />
											</Route>
										),
									}[profile?.role]
								}
							<Route path="/login" element={<Navigate to='/' />} />
							<Route path="/sign-up" element={<Navigate to='/' />} />
							<Route path="/waiting-confirm" element={<Navigate to='/' />} />
							<Route path="/waiting-restore" element={<Navigate to='/' />} />
							<Route path="/waiting-confirm-teacher" element={<Navigate to='/' />} />
							<Route path="/verify-email/:token" element={<Navigate to='/' />} />
							<Route path="/reset-password/:email/:token" element={<Navigate to='/' />} />
							<Route path="/forgot-password" element={<Navigate to='/' />} />
						</>
					) : (
						<Route path="*" element={<div style={{height: '100svh', width: '100vw'}}><Loader/></div>}/>
					)}
				</> : (
					<>
						<Route element={<MainLayout />}>
							<Route path="/auth/vk/redirect" element={<VkRedirect />} />
							<Route path="/waiting-confirm" element={<AfterRegistration />} />
							<Route path="/waiting-restore" element={<WaitingRestore />} />
							<Route path="/waiting-confirm-teacher" element={<AfterRegistrationTeacher />} />
							<Route path="/verify-email/:token" element={<VerifyPage />} />
						</Route>
						<Route element={<><Outlet/></>} >
							<Route path="/login" element={<LoginPage />} />
							<Route path="/sign-up/:referral?" element={<RegisterPage />} />
							<Route path="/reset-password/:email/:token" element={<ResetPassword />} />
							<Route path="/forgot-password" element={<ForgetPasswordPage />} />
						</Route>
						<Route path="/" element={<Navigate to='/login' />} />
						<Route path="/teacher/:id" element={<Navigate to='/login' />} />
						<Route path="*" element={<NotFound />}/>
					</>
				)
       }
        <Route path="*" element={<NotFound />}/>
      </RouterDomRoutes>
    </>
  );
}
